import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    textContainer: {
      height: "596px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        height: "532px",
      },
    },
    text: {
      maxWidth: "596px",
      textAlign: "center",
      margin: "auto",
      color: theme.palette.common.white,
    },
    button: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
    },
  });

import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { HeaderComponentType } from "@src/layouts/MainLayout";
import { PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import HeaderWithBackground from "./HeaderWithBackground";
import TIStickerTemplate from "./TIStickerTemplate";

export type TIStickerPageProps = PageWithImagesProps<
  "iSticker" | "iStickerBackground"
> & {};

class TIStickerPage extends React.Component<TIStickerPageProps> {
  public render() {
    return (
      <IndexLayout
        headerStyle="light"
        {...this.props}
        headerComponent={HeaderWithBackground as HeaderComponentType}>
        <SeoHead
          title={t("The first Telematics working on solar energy")}
          description={t(
            "A simple to install solution, which brings telematics services to any vehicle in 5 minutes! Trucknet integration is provided to share real-time location with your Contractors and Customers.",
          )}
          keywords={[
            t("ti-sticker"),
            t("solar energy"),
            t("quick instalation"),
            t("gps"),
            t("solution"),
          ]}
        />
        <TIStickerTemplate />
      </IndexLayout>
    );
  }
}

export default TIStickerPage;

import { Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import { styles } from "./BenefitItem.styles";

export type BenefitItemProps = WithStyles<typeof styles> & {
  icon: SvgComponent;
  title: React.ReactNode;
  content: React.ReactNode;
};

class BenefitItem extends React.PureComponent<BenefitItemProps> {
  public render() {
    const { icon: Icon, title, content, classes } = this.props;

    return (
      <div className={classes.itemContainer}>
        <Icon className={classes.icon} />
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1">{content}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(BenefitItem);

import {
  Container,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import ButtonLink from "@src/components/ButtonLink";

import { styles } from "./MainInfo.styles";

export type MainInfoProps = WithStyles<typeof styles> & {
  className?: string;
};

class MainInfo extends React.PureComponent<MainInfoProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <Container className={cn(className, classes.textContainer)}>
        <Typography variant="h2" className={classes.text}>
          <T message="The first Telematics device working on solar energy" />
        </Typography>
        <Typography variant="h5" className={classes.text}>
          <T message="Professional telematics system in the form of a small sticker" />
        </Typography>
        <ButtonLink
          className={classes.button}
          external
          to="https://isticker.trucknet.io/"
          variant="contained"
          color="secondary">
          <T message="Enter the dashboard" />
        </ButtonLink>
      </Container>
    );
  }
}

export default withStyles(styles)(MainInfo);

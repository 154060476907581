import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    blockContainer: {
      maxWidth: "880px",
      margin: "auto",
    },
    title: {
      margin: "auto",
      textAlign: "center",
    },
    itemsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    phoneLink: {
      display: "block",
      color: theme.palette.trucknet.blue,
      "&:hover, &:hover > span": {
        color: theme.palette.trucknet.blue,
      },
    },
  });

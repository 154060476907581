import { Container, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import BenefitsBlock from "./BenefitsBlock";
import ExplanationCard from "./ExplanationCard";
import MainInfo from "./MainInfo";
import { styles } from "./TIStickerTemplate.styles";

export type IStickerTemplateProps = WithStyles<typeof styles> & {};

class TIStickerTemplate extends React.PureComponent<IStickerTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Container className={classes.container}>
        <MainInfo className={classes.mainInfo} />
        <ExplanationCard className={classes.explanationCard} />
        <BenefitsBlock className={classes.benefitsBlock} />
      </Container>
    );
  }
}

export default withStyles(styles)(TIStickerTemplate);

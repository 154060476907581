import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      alignItems: "center",
      margin: "auto",
      position: "relative",
      padding: theme.spacing(5, 15),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(5, 3),
      },
    },
    greenDots: {
      position: "absolute",
      transform: "rotateZ(90deg)",
      height: "512px",
      right: theme.spacing(17),
      top: theme.spacing(-27),
      [theme.breakpoints.down("xs")]: {
        right: theme.spacing(-30),
      },
    },
    textContainer: {
      width: "350px",
      marginRight: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(5),
      },
      "@media(max-width: 749px)": {
        width: "100%",
        marginRight: 0,
        marginBottom: theme.spacing(5),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "450px",
      "@media(max-width: 749px)": {
        width: "100%",
      },
    },
  });

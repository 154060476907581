import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(25),
    },
    mainInfo: {
      marginTop: theme.spacing(13),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(17.5),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(12.75),
      },
    },
    explanationCard: {
      marginTop: theme.spacing(20),
    },
    benefitsBlock: {
      marginTop: theme.spacing(20),
    },
  });

import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    container: {
      position: "relative",
    },
    backgroundWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
    },
  });

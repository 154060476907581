import { Paper, Typography, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";
import { T } from "react-targem";

import GreenDots from "@src/components/GreenDots";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { TIStickerPageProps } from "@src/templates/TIStickerPage";

import { styles } from "./ExplanationCard.styles";

export type ExplanationCardProps = WithStyles<typeof styles> &
  WithPageData<TIStickerPageProps> & {
    className?: string;
  };

class ExplanationCard extends React.PureComponent<ExplanationCardProps> {
  public render() {
    const { pageData, classes, className } = this.props;

    return (
      <Paper elevation={7} className={cn(className, classes.paper)}>
        <GreenDots className={classes.greenDots} />
        <div className={classes.textContainer}>
          <Typography variant="h3" className={classes.title}>
            <T message="TI-Sticker" />
          </Typography>
          <Typography variant="body1">
            <T message="A simple to install solution, which brings telematics services to any vehicle in 5 minutes! Trucknet integration is provided to share real-time location with your Contractors and Customers." />
          </Typography>
        </div>
        <GatsbyImage
          fluid={pageData.iSticker.childImageSharp.fluid}
          className={classes.image}
        />
      </Paper>
    );
  }
}

export default withPageData(withStyles(styles)(ExplanationCard));

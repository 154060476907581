// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import TIStickerPage from "@src/templates/TIStickerPage";
export default TIStickerPage;
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query TIStickerPageQuery {
    iSticker: file(relativePath: { eq: "images/isticker.png" }) {
      childImageSharp {
        fluid(maxWidth: 605) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iStickerBackground: file(
      relativePath: { eq: "images/isticker_background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    itemContainer: {
      marginTop: theme.spacing(10),
      width: "calc(50% - 40px)",
      [theme.breakpoints.down("sm")]: {
        width: "calc(50% - 20px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: theme.spacing(6),
      },
    },
    icon: {
      width: "72px",
      height: "72px",
      marginBottom: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
  });

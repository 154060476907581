import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    backgroundWrapper: {
      zIndex: -1,
      height: "800px",
      [theme.breakpoints.down("xs")]: {
        height: "688px",
      },
    },
    auxilaryLayer: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.common.black,
      opacity: 0.56,
      position: "absolute",
    },
    background: {
      width: "100%",
      height: "100%",
      zIndex: -2,
    },

    title: {
      marginBottom: theme.spacing(2),
    },
    text: {
      maxWidth: "596px",
      textAlign: "center",
      margin: "auto",
      color: theme.palette.common.white,
    },
  });

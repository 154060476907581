import { Link, Typography, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T, withLocale, WithLocale } from "react-targem";

import AffordableSolutionSvg from "@src/assets/icons/iSticker/AffordableSolution.svg";
import EnergySvg from "@src/assets/icons/iSticker/Energy.svg";
import GpsSvg from "@src/assets/icons/iSticker/Gps.svg";
import TimeSvg from "@src/assets/icons/iSticker/Time.svg";
import { contactPhone } from "@src/utils/locale";

import BenefitItem from "./BenefitItem";
import { styles } from "./BenefitsBlock.styles";

export type BenefitsBlockProps = WithStyles<typeof styles> &
  WithLocale & {
    className?: string;
  };

class BenefitsBlock extends React.PureComponent<BenefitsBlockProps> {
  public render() {
    const { classes, className, locale } = this.props;

    return (
      <div className={cn(className, classes.blockContainer)}>
        <Typography variant="h3" className={classes.title}>
          <T message="TI - Sticker benefits" />
        </Typography>
        <div className={classes.itemsWrapper}>
          <BenefitItem
            icon={TimeSvg}
            title={<T message="Installation in 5 minutes" />}
            content={
              <T message="No need to connect to the hardware of the truck. Just stick it on the windshield and you are ready to go! No need to go to the garage to install the hardware in your vehicles! " />
            }
          />
          <BenefitItem
            icon={EnergySvg}
            title={<T message="Solar energy is used" />}
            content={
              <T message="The device is not connected to any power source so there are no extra cables in the cabin. Many challenges were overcome in order for this device to work autonomously on solar power only." />
            }
          />
          <BenefitItem
            icon={GpsSvg}
            title={<T message="The most accurate GPS on the market" />}
            content={
              <T message="Machine learning and gyroscope are used to enhance the data received from the  GPS sensor and to provide an accurate location of the vehicle, within 2 meters." />
            }
          />
          <BenefitItem
            icon={AffordableSolutionSvg}
            title={<T message="The most affordable solution on the market" />}
            content={
              <>
                <T message="The TI-sticker does not require any additional hardware to be installed in the vehicle, so you pay for the device only. Contact our sales department for further information." />
                <Link
                  href={`tel:${contactPhone[locale]}`}
                  className={classes.phoneLink}>
                  <span dir="ltr">{contactPhone[locale]}</span>
                </Link>
              </>
            }
          />
        </div>
      </div>
    );
  }
}

export default withLocale(withStyles(styles)(BenefitsBlock));

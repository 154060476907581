import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import { styles } from "./HeaderWithBackground.styles";

export type HeaderWithBackgroundProps = WithStyles<typeof styles> & {
  header: JSX.Element;
  children: React.ReactNode;
  className?: string;
};

class HeaderWithBackground extends React.PureComponent<
  HeaderWithBackgroundProps
> {
  public render() {
    const { classes, header, children, className } = this.props;

    return (
      <div className={classes.container}>
        <div className={cn(classes.backgroundWrapper, className)}>
          {children}
        </div>
        {header}
      </div>
    );
  }
}

export default withStyles(styles)(HeaderWithBackground);
